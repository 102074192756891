import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import LeadFormWithAPI from "../components/LeadFormWithAPI";
import SEO from "../components/seo";
import "../styles/page/landing_page.scss";
import VideoComponent from "../components/VideoComponent";

const SplitSection = ({
  icon,
  title,
  description,
  learn_more_link,
  image,
  video,
  learnMorehref,
  direction = "row",
  subTitle = "Learn more",
}) => {
  const flexDirection =
    direction === "row-reverse" ? "sm:flex-row-reverse" : "sm:flex-row";
  return (
    <section className="splitSection pt-[30px] sm:pt-0">
      <section
        className={`flex flex-col items-center  ${flexDirection} gap-8 lg:gap-20`}
      >
        <section className="pl-[20px] sm:pl-0 pr-[15px] sm:pr-0 flex-1 flex gap-2 md:gap-3 lg:gap-[20px]">
          <section className="hidden sm:block w-[44px] lg:w-[72px] h-[44px] lg:h-[71px] flex-shrink-0">
            {icon}
          </section>
          <section className="text-blue-1100 max-w-[431px]">
            <h3 className="text-[20px] sm:text-[25px] lg:text-[36px] leading-6 sm:leading-[30.26px] lg:leading-11 font-medium tracking-[-0.05em] ">
              {title}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              className="text-sm sm:text-[17px] lg:text-xl leading-5 sm:leading-5 lg:leading-7 mt-[5px] feature_description"
            />
            <a
              href={learnMorehref}
              target="__blank"
              rel="noopener noreferrer"
              className="text-sm font-bold leading-5 sm:text-base lg:text-xl lg:leading-7"
            >
              {subTitle}
            </a>
          </section>
        </section>
        <section className="flex-1 lg:h-[380px] xl:h-[410px] ">
          {video ? (
            <VideoComponent loop autoPlay muted>
              <source src={video} type="video/mp4" />
            </VideoComponent>
          ) : (
            <>
              <img
                src={image?.desktop?.url}
                alt={image?.altText || "img"}
                loading="lazy"
                className="hidden w-auto h-auto mx-auto xs:block lg:h-full"
                width={0}
                height={0}
              />
              <img
                src={image?.mobile?.url}
                alt={image?.altText || "img"}
                loading="lazy"
                className="block w-auto h-auto xs:hidden"
                width={0}
                height={0}
              />
            </>
          )}
        </section>
      </section>
    </section>
  );
};

const TrainingPage = ({ location, data }) => {
  const _data = data?.prismicEcgAnnotationToolPage?.data || {};
  const [trusted_brands_list] = _data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  ) || [[]];
  const [sub_features_list] = _data?.body?.filter(
    (v) => v.slice_type === "sub_features_list"
  ) || [[]];
  const [encord_features] = _data?.body?.filter(
    (v) => v.slice_type === "encord_features"
  ) || [[]];

  return (
    <Layout location={location}>
      <article className="px-4 pt-32 pb-10 mx-auto space-y-6 text-center sm:px-6 md:pt-40">
        <p className="text-base text-purple-500 leading-[136.52%]">
          {_data?.page_label?.text}
        </p>
        <div
          className="heading_gradient heading_gradient_h1"
          dangerouslySetInnerHTML={{
            __html: _data?.page_heading?.html,
          }}
        />

        <p className="max-w-[700px] mx-auto px-6 text-xl">
          {_data?.page_description?.text}
        </p>

        <section className="flex flex-col items-center">
          <LeadFormWithAPI pageName={"new landing page"} location={location} />
          {_data?.main_page_video?.url ? (
            <VideoComponent
              width="100%"
              autoPlay={true}
              preload="auto"
              controls={false}
              loop
              muted
              className="mt-2 md:mt-[34px] xl:w-[1358px] xl:h-[644px] object-cover rounded-md"
            >
              <source src={_data?.main_page_video?.url} type="video/mp4" />
            </VideoComponent>
          ) : (
            <img
              src={_data?.hero_image?.url}
              alt={_data?.main_image?.alt || "img"}
              className="object-cover w-auto h-auto rounded-md"
              loading="lazy"
              width={0}
              height={0}
            />
          )}
        </section>
      </article>

      {/*  sections two*/}
      <article className="max-w-2xl mx-auto">
        <section className="img_grayscale_opacity section_container mx-auto py-9.5">
          <section>
            <p className="text-center text-sm text-blue-1200  tracking-[0.07em]">
              {_data?.trusted_brands_list_label?.text}
            </p>
          </section>
          <section className={`grid grid-cols-2 gap-8 md:grid-cols-6`}>
            {trusted_brands_list?.items?.map((item, index) => {
              return (
                <div
                  key={`brand_${index}`}
                  className="flex justify-center col-span-1 md:col-span-4 lg:col-span-1"
                >
                  <img
                    src={item?.brand_image?.url}
                    alt={item?.brand_image?.alt || "img"}
                    className={`${"object-scale-down h-auto w-auto"} img_grayscale`}
                    loading="lazy"
                    width={0}
                    height={0}
                  />
                </div>
              );
            })}
          </section>
        </section>
      </article>

      <article className="section_container relative mx-auto mt-[40px] md:mt-[91px]">
        <section className="al_assisted_card lg:max-w-[1020px] xl:max-w-[1167px] ml-auto py-7 md:py-9 lg:py-16 px-[6px] items-center lg:items-start">
          <section className="flex-1">
            {/* Need to change styling for video */}
            <div className="static left-0 h-auto xl:w-auto xl:absolute top-8">
              {encord_features?.items[0]?.feature_video_url?.url ? (
                <VideoComponent
                  loop
                  autoPlay
                  muted
                  className="w-auto xl:max-w-[592px] rounded-lg"
                >
                  <source
                    src={encord_features?.items[0]?.feature_video_url?.url}
                    type="video/mp4"
                  />
                </VideoComponent>
              ) : (
                <img
                  src={encord_features?.items[0]?.feature_image?.url}
                  alt="ai dashboard"
                  className="w-auto xl:max-w-[592px] rounded-lg h-auto"
                  loading="lazy"
                  width={0}
                  height={0}
                />
              )}
            </div>
          </section>
          <section className="flex flex-col flex-1 pl-[20px] sm:pl-0 pr-[15px] sm:pr-0">
            <section className="text-blue-1100 max-w-[431px]">
              <h2 className="text-[20px] leading-6 lg:text-[38px] xl:text-[47px] lg:leading-[56.99px] tracking-[-0.05em]">
                {encord_features?.items[0]?.feature_heading?.text}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: encord_features?.items[0]?.feature_description?.html,
                }}
                className="text-base lg:text-xl events_page_feature_description mt-[5px]"
              />

              <a
                href={encord_features?.items[0]?.learn_more_link?.text || ""}
                target="__blank"
                rel="noopener noreferrer"
                className="text-sm font-bold leading-5 sm:text-base lg:text-xl lg:leading-7"
              >
                Learn more
              </a>
            </section>
          </section>
        </section>
      </article>

      <article className="section_container mx-auto pt-[86px] lg:pt-[91px] space-y-14 lg:space-y-[161px] bg-white">
        {encord_features?.items?.slice(1, 2)?.map((item, i) => {
          const rowReverse = i % 2 === 0;
          return (
            <SplitSection
              key={i}
              video={item?.feature_video_url?.url}
              image={{
                desktop: {
                  url: item?.feature_image?.url,
                },
                mobile: {
                  url: item?.feature_image_mobile?.url,
                },
              }}
              icon={<></>}
              learnMorehref={item?.learn_more_link?.text}
              title={item?.feature_heading?.text}
              description={item?.feature_description?.html}
              learn_more_link={item?.learn_more_link?.text}
              direction={rowReverse ? "row" : "row-reverse"}
            />
          );
        })}
      </article>

      {sub_features_list?.items?.length && (
        <article className="section_container mx-auto pt-[10px]">
          <section
            className="flex justify-center gap-2 md:gap-4 lg:gap-10 xl:gap-[68px] flex-wrap lg:flex-nowrap sm:my-14 my-6 sm:rounded-4xl rounded-[25px] shadow-lg"
            style={{
              background: "linear-gradient(180deg, #FCFCFF 0%, #F4F4FF 100%)",
            }}
          >
            {sub_features_list?.items?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col sm:pt-14 pt-6 sm:pb-8 pb-4 max-w-[324px]"
                >
                  {item?.feature_video?.url ? (
                    <VideoComponent
                      loop
                      autoPlay
                      muted
                      playsinline
                      width={324}
                      className="rounded-[23.103px] h-[222px] max-w-[324px] object-none"
                    >
                      <source src={item?.feature_video?.url} type="video/mp4" />
                    </VideoComponent>
                  ) : (
                    <img
                      src={item?.feature_image?.url}
                      alt={"imgAlt"}
                      width={324}
                      className="rounded-[23.103px] h-[222px] max-w-[324px] object-none"
                      loading="lazy"
                      height={0}
                    />
                  )}
                  <h4 className="sm:text-[30px] text-[20px] text-blue-1100 font-normal sm:pt-[20px] pt-[15px]">
                    {item?.feature_heading?.text}
                  </h4>
                  <div
                    className="sm:text-[15.4px] text-[12px] font-normal sub_feature_description"
                    dangerouslySetInnerHTML={{
                      __html: item?.feature_description?.html,
                    }}
                  />
                </div>
              );
            })}
          </section>
        </article>
      )}

      <article className="section_container mx-auto pt-[86px] lg:pt-[91px] space-y-14 lg:space-y-[161px] bg-white">
        {encord_features?.items?.slice(2, 4)?.map((item, i) => {
          const rowReverse = i % 2 === 0;

          return (
            <>
              <SplitSection
                key={i}
                video={item?.feature_video_url?.url}
                image={{
                  desktop: {
                    url: item?.feature_image?.url,
                  },
                  mobile: {
                    url: item?.feature_image_mobile?.url,
                  },
                }}
                icon={<></>}
                learnMorehref={item?.learn_more_link?.text}
                title={item?.feature_heading?.text}
                description={item?.feature_description?.html}
                direction={rowReverse ? "row" : "row-reverse"}
              />
            </>
          );
        })}
      </article>

      <article className="py-[86px] px-5 md:py-[150px] relative  overflow-hidden mt-[70px]">
        <section className="absolute inset-0 bg-purple-1000 -z-20" />
        <section className="absolute -bottom-10 md:bottom-0 -right-96 md:-right-60 lg:right-0 -z-10">
          <img
            src="https://images.prismic.io/encord/567d7939-a847-4c3e-99e4-e9f8057390c6_3D+Shape+Hero+Image+-+Glow+Effect+-+Dark+Theme+-+Dark+Theme+With+glass+6+-+Large+2+1.png?auto=compress,format"
            alt="3d modal"
            loading="lazy"
            className="min-w-[1290px] w-auto h-auto"
            width={0}
            height={0}
          />
        </section>
        <section className="max-w-[1000px] mx-auto text-center ">
          <h2 className="text-[36px] md:text-[44px] lg:text-[80px] text-white leading-11 md:leading-[53.25px] lg:leading-[96.82px] tracking-[-0.03em] font-semibold">
            {_data?.cta_heading?.text}
          </h2>
          <p className="mb-[50px] text-xl text-white leading-6 mt-9 tracking-[-0.025em]">
            {_data?.cta_description?.text}
          </p>
          <div className="flex flex-col items-center justify-center w-full space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
            <LeadFormWithAPI pageName="Landing Page" location={location} />
          </div>
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicEcgAnnotationToolPage {
      data {
        body {
          ... on PrismicEcgAnnotationToolPageDataBodyEncordFeatures {
            id
            slice_type
            items {
              feature_description {
                html
                text
              }
              feature_heading {
                html
                text
              }
              feature_image {
                alt
                url
              }
              feature_image_mobile {
                alt
                url
              }
              feature_video_url {
                url
              }
              heading_icon {
                alt
                url
              }
              learn_more_link {
                text
                html
              }
              lean_more_text {
                text
                html
              }
            }
          }
          ... on PrismicEcgAnnotationToolPageDataBodySubFeaturesList {
            slice_type
            items {
              feature_description {
                html
                text
              }
              feature_heading {
                html
                text
              }
              feature_image {
                alt
                url
              }
              feature_video {
                url
              }
            }
          }
          ... on PrismicEcgAnnotationToolPageDataBodyTrustedBrandsList {
            slice_type
            items {
              brand_image {
                alt
                url
              }
            }
          }
        }
        cta_description {
          html
          text
        }
        cta_heading {
          html
          text
        }
        hero_image {
          alt
          url
        }
        meta_description {
          html
          text
        }
        meta_heading {
          html
          text
        }
        page_description {
          html
          text
        }
        page_heading {
          html
          text
        }
        page_label {
          html
          text
        }
        trusted_brands_list_label {
          html
          text
        }
      }
    }
  }
`;
export const Head = ({ data }) => {
  const _data = data?.prismicEcgAnnotationToolPage?.data;
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default TrainingPage;
